<template>
  <el-dialog
    :model-value="dialogVisible"
    title="批量新增节点"
    width="700px"
    @opened="handleOpen"
    @close="handleClose"
    draggable
  >
    <el-form :model="form" label-width="90px" ref="formRef" :rules="rules">
      <el-form-item label="节点分组" prop="groupID">
        <el-select
          v-model="form.groupID"
          placeholder="请选择分组"
          style="width: 100%"
          @change="selectchange"
        >
          <el-option
            v-for="item in GroupList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-upload
          class="upload-demo"
          multiple
          :limit="20"
          action="#"
          list-type="picture-card"
          @change="uploadefile"
          :file-list="fileList"
          :auto-upload="false"
        >
          <el-button type="primary">选择图片</el-button>
          <template #tip>
            <div class="el-upload__tip">每次最多20张图片</div>
          </template>
        </el-upload>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { UploadeImages } from '@/api/Node'
import { GetNodeGroupFillList } from '@/api/NodeGroup'
import { ElMessage } from 'element-plus'
const dialogVisible = ref(false)
const formRef = ref(null)

const fileList = ref([])
var fileitem = new FormData()

var form = ref({})

const uploadefile = (uploadFile, uploadFiles) => {
  fileList.value = uploadFiles
  fileitem.append('file', uploadFile.raw)
}

const selectchange = (obj) => {
  let info = form.value.groupID.split('@')
  fileitem.set('groupID', info[0])
  fileitem.set('groupName', info[1])
}
const rules = reactive({
  groupID: [{ required: true, message: '节点分组不能为空', trigger: 'blur' }]
})
const emits = defineEmits(['update:modelValue', 'initList'])
const handleOpen = () => {}
const handleClose = () => {
  emits('update:modelValue', false)
}
const handleConfirm = () => {
  UploadeImages(fileitem)
    .then((res) => {
      console.log(res)
      if (res.Code == 200) {
        ElMessage({
          message: '操作成功',
          type: 'success'
        })
        emits('initList')
        handleClose()
      } else {
        ElMessage({
          message: '操作失败!错误信息:' + res.Msg,
          type: 'error'
        })
      }
    })
    .catch((ex) => {
      console.log('出现错误:' + ex)
      ElMessage({
        message: '操作异常!错误信息:' + res.Msg,
        type: 'error'
      })
    })
}
const GroupList = reactive([])

const InitList = () => {
  GetNodeGroupFillList()
    .then((res) => {
      GroupList.length = 0
      res.Data.forEach((item) => {
        GroupList.push({
          id: item.ID + '@' + item.Name,
          name: item.Name
        })
      })
    })
    .catch((err) => {
      console.log(err)
    })
}
InitList()
</script>

<style scoped>
.avatar-uploader .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
.el-upload-list--picture-card .el-upload-list__item {
  float: left;
  max-width: 180px;
}
.upload-demo {
  width: 100%;
}
</style>
