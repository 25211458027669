<template>
  <el-dialog
    :model-value="dialogVisible"
    :title="dialogTitle"
    width="500px"
    @opened="handleOpen"
    @close="handleClose"
    draggable
  >
    <el-form :model="form" label-width="85px" ref="formRef" :rules="rules">
      <el-form-item label="节点名称" prop="Name">
        <el-input v-model="form.Name" />
      </el-form-item>
      <el-form-item label="节点分组" prop="GroupID">
        <el-select
          v-model="form.GroupID"
          placeholder="Select"
          style="width: 100%"
        >
          <el-option
            v-for="item in groupInfo"
            :key="item.ID"
            :label="item.Name"
            :value="item.ID"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="节点类型" prop="type">
        <el-select v-model="form.Type" style="width: 100%">
          <el-option
            v-for="item in NodeTypeList"
            :key="item.typeValue"
            :label="item.typeDesc"
            :value="item.typeValue"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="节点序号" prop="Sort">
        <el-input v-model="form.Sort" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item :label="form.type == 3 ? '节点路由' : '节点内容'">
        <el-input
          v-model="form.Info"
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 10 }"
        />
      </el-form-item>
      <el-form-item v-if="form.Type == 3">
        <el-upload
          class="avatar-uploader"
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
        >
          <img v-if="form.Info" :src="imageUrl" class="avatar" />
          <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
        </el-upload>
      </el-form-item>
      <el-form-item v-if="form.Type == 2">
        <el-upload
          :auto-upload="false"
          class="avatar-uploader"
          :on-change="handleChangeImg"
        >
          <img v-if="form.Info" :src="form.Info" class="avatar" />
          <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
        </el-upload>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { AddNode, EditNode } from '@/api/Node'
import { ElMessage } from 'element-plus'
import { isNull } from '@/utitls/validate'
import { GetNodeGroupFillList } from '@/api/NodeGroup'
import { GetNodeTypes } from '@/api/EnumInfo'
import { getBase64 } from '@/utitls/imgToBase64'
const props = defineProps({
  dialogTitle: {
    type: String,
    default: '',
    required: true
  },
  dialogRowValue: {
    type: Object,
    default: () => {}
  }
})
const formRef = ref(null)
var form = ref({})
let uploadUrl =
  process.env.NODE_ENV === 'development'
    ? '/api/Node/UploadImage'
    : window.global_config.BASE_URL + 'api/Node/UploadImage'
let imageUrl = ref('')
const dialogVisible = ref(false)
const rules = reactive({
  Name: [{ required: true, message: '节点名称不能为空', trigger: 'blur' }],
  GroupID: [{ required: true, message: '节点分组不能为空', trigger: 'blur' }]
})
const groupInfo = ref([])
const NodeTypeList = reactive([])
const emits = defineEmits(['update:modelValue', 'initList'])
const handleOpen = () => {
  GetNodeGroupFillList()
    .then((res) => {
      if (res) {
        res.Data.forEach((item) => {
          groupInfo.value.push({
            ID: item.ID,
            Name: item.Name
          })
        })
      }
    })
    .catch((err) => {
      ElMessage({
        showClose: true,
        message: `${err}`,
        type: 'error'
      })
    })
  GetNodeTypes()
    .then((res) => {
      NodeTypeList.length = 0
      res.Data.forEach((item) => {
        NodeTypeList.push({
          typeValue: item.TypeValue,
          typeName: item.TypeName,
          typeDesc: item.TypeDesc
        })
      })
    })
    .catch((err) => {
      console.log(err)
    })
  if (props.dialogTitle === '修改组态节点信息') {
    form.value = props.dialogRowValue
    imageUrl.value =
      process.env.NODE_ENV === 'development'
        ? form.value.Info
        : window.global_config.BASE_URL + form.value.Info
  }
}
const handleClose = () => {
  emits('update:modelValue', false)
}
const handleConfirm = () => {
  formRef.value.validate(async (valid, fields) => {
    if (valid) {
      if (props.dialogTitle == '添加组态节点') {
        AddNode(form.value)
          .then((res) => {
            ElMessage({
              showClose: true,
              message: '操作成功',
              type: 'success'
            })
            emits('initList')
            handleClose()
          })
          .catch((err) => {
            ElMessage({
              showClose: true,
              message: `操作失败:${err}`,
              type: 'error'
            })
          })
      } else
        EditNode(form.value)
          .then((res) => {
            ElMessage({
              showClose: true,
              message: '操作成功',
              type: 'success'
            })
            emits('initList')
            handleClose()
          })
          .catch((err) => {
            ElMessage({
              showClose: true,
              message: `操作失败:${err}`,
              type: 'error'
            })
          })
    } else {
      console.log('error submit!', fields)
    }
  })
}
//图片上传
const handleAvatarSuccess = (response, uploadFile) => {
  form.value.Info = response.Data
  imageUrl.value =
    process.env.NODE_ENV === 'development'
      ? response.Data
      : window.global_config.BASE_URL + response.Data
}
const handleChangeImg = (file, fileList) => {
  getBase64(file.raw).then((res) => {
    form.value.Info = res
  })
}
</script>
<style scoped>
.avatar-uploader .avatar {
  width: 150px;
  height: 150px;
  display: block;
}
</style>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
</style>
